import React, { createElement } from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Markdown from "marked-react";

import Header from "@components/Header";
import { useStaticAssets } from "@hooks/queries";
import { Link } from "gatsby";
import Footer from "@components/Footer";
import Seo from "@components/Seo";

export default function Project({ data }) {
  let { lineArrow } = useStaticAssets();

  let project = data.project,
    nextProject = data.nextProject,
    previousProject = data.previousProject;

  /*
   * shift heading levels so h(n) becomes h(n + 1)
   * This prevents rendering of h1 heading for returned markdown content as
   * page title is already a h1 heading
   */
  const headingLevelsShiftOne = {
    heading: (text, level) => {
      let reactElement = createElement(
        `h${level + 1}`,
        { className: `singleProject__section-title` },
        text
      );
      return reactElement;
    },
  };

  return (
    <>
      <Seo title={project.title} description={project.excerpt} />
      <div className="singleProject">
        <header className="singleProject__pageTop">
          <div className="singleProject__header">
            <Header currentPage="projects" variant="dark" />
          </div>
          <div className="singleProject__date">
            <time dateTime={project.published_at_raw}>
              {project.published_at}
            </time>
          </div>
          <div className="singleProject__hero">
            <GatsbyImage
              image={getImage(project.banner_image.localFile)}
              loading="eager"
              alt={project.title}
            />
            <div className="singleProject__hero-header">
              <h1>{project.title}</h1>
              <time dateTime={project.published_at_raw}>
                {project.published_at}
              </time>
            </div>
          </div>
        </header>
        <main>
          <article className="singleProject__post">
            <section>
              <h2 className="singleProject__section-title">Project brief</h2>
              <Markdown>{project.project_brief}</Markdown>
            </section>
            <section>
              <Markdown renderer={headingLevelsShiftOne}>
                {project.content}
              </Markdown>
            </section>
          </article>
          <div className="singleProject__nav">
            {previousProject && (
              <Link
                className="singleProject__nav-link singleProject__nav-link--prev"
                to={`/projects/${previousProject.fields.slug}`}
              >
                <span className="singleProject__nav-text singleProject__nav-text--prev">
                  <img src={lineArrow.publicURL} alt="" />
                  <span>
                    PREVIOUS{" "}
                    <span className="h-phone-only-hidden">PROJECT</span>
                  </span>
                </span>
                <span className="singleProject__nav-title">
                  {previousProject.title}
                </span>
              </Link>
            )}
            {nextProject && (
              <Link
                className="singleProject__nav-link singleProject__nav-link--next"
                to={`/projects/${nextProject.fields.slug}`}
              >
                <span className="singleProject__nav-text singleProject__nav-text--next">
                  <span>
                    NEXT <span className="h-phone-only-hidden">PROJECT</span>
                  </span>
                  <img src={lineArrow.publicURL} alt="" />
                </span>
                <span className="singleProject__nav-title singleProject__nav-title--next">
                  {nextProject.title}
                </span>
              </Link>
            )}
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
}

export const query = graphql`
  query Project(
    $id: String!
    $previousProjectId: String
    $nextProjectId: String
  ) {
    project: strapiProject(id: { eq: $id }) {
      id
      title
      project_brief
      content
      excerpt
      banner_image {
        localFile {
          childImageSharp {
            gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
          }
        }
      }
      gallery_images {
        id
        localFile {
          childImageSharp {
            gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
          }
        }
      }
      published_at(formatString: "MMMM Do, YYYY")
      published_at_raw: published_at
    }
    previousProject: strapiProject(id: { eq: $previousProjectId }) {
      title
      fields {
        slug
      }
    }
    nextProject: strapiProject(id: { eq: $nextProjectId }) {
      title
      fields {
        slug
      }
    }
  }
`;
